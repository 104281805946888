<template>
  <div>
    <v-app-bar fixed app>
      <v-app-bar-nav-icon @click.stop="toggleSidebar"></v-app-bar-nav-icon>

      

      <v-spacer></v-spacer>

      <Search />

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <table>
            <tr>
              <td>
                <v-avatar v-bind="attrs" v-on="on">
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </td>
              
            </tr>
          </table>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title @click="close">Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import Search from "@/components/Search/Search.vue";
export default {
  name: "Topbar",
  components: { Search },

  methods: {
    toggleSidebar(){
      this.$store.commit('setShowSidebar', !this.$store.state.showSidebar)
    },
    close() {
      this.$router.push("/login");
    },
  },
};
</script>