<template>
  <div>
    <v-switch
      v-model="item.status"
      hide-details
      @click="saveStatus"
      :loading="loading"
      :title="item.status ? 'Ativo':'Inativo'"
      inset
      class="teste"
    ></v-switch>
  </div>
</template>

<script>
  export default {
    props: ['item'],
    data: () => ({
      loading: false
    }),
    methods: {
      async saveStatus(){
        this.loading = true
        await this.$store.state.axios.put('/student/status/', {status: this.item.status, _id: this.item._id})
          .then(({data})=>{
            this.$store.dispatch('alerts/success', data.message)
          })
          .catch(err => {
            this.$store.dispatch('alerts/error', "houve uma falha no servidor ----- "+(err.response?.status || err.stack)+" -----")
            this.item.status = !this.item.status
          })
        this.loading=false
      }
    }
  }
</script>

<style  scoped>
>>> .v-input--selection-controls {
  margin-top:0;
  padding: 0;
  padding-left: 5px

}
</style>