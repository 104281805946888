<template>
  <table width="80%">
    <tr>
      <td>
        <v-autocomplete
          v-model="studentId"
          :search-input.sync="search"
          :loading="loading"
          :items="items"
          hide-no-data
          item-text="name"
          item-value="_id"
          dense
          outlined
          label="Pesquisar por Aluno"
          hide-details
          prepend-inner-icon="mdi-magnify"
          @change="registration"
        >
          <template #item="{ item }">
            <div
              style="width: 100%"
              @keypress.enter="registration"
              @click="registration"
            >
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
      </td>

      <td>
        <v-btn icon :disabled="disabledAdd" @click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data: () => ({
    disabledAdd: true,
    loading: false,
    search: "",
    studentId: "",
    timeout: 0,
    items: [],
    name: ''
  }),
  methods: {
    add(){
      this.$store.dispatch('alerts/confirm', 'Deseja criar um novo aluno?')
      //this.$store.state.axios.post('')
      this.disabledAdd = true
    },
    registration() {
      if (
        !this.studentId ||
        this.$route.path == "/registration/" + this.studentId
      )
        return;
      this.$router.push("/registration/" + this.studentId);
    },
    getStudentByName() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (!this.search) return;
        this.loading = true;
        this.items = await this.$store.state.axios
          .get("/students/" + this.search)
          .then(({ data }) => data);
        this.loading = false;
      }, 200);
    },
  },
  watch: {
    async "$store.state.alerts.confirm"(v){
      if(v){
        const {data} = await this.$store.state.axios.post('/student/create', {name: this.name})
        this.$store.commit(
        "student/setData",
        data
      );
      this.$router.push("/registration/" + data._id);
      }
    },
    search(v) {
      if (!v) return;
      this.getStudentByName();
    },
    studentId(v) {
      if (!v) return;
      this.$store.commit(
        "student/setData",
        this.items.find((item) => item._id === v)
      );
    },
    items(v) {
      this.name = this.search
      this.disabledAdd = !!v.length;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>