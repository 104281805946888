export default {
  namespaced: true,
  state: ()=>({
    isAuthenticated: false,
    token: null
  }),
  mutations:{
    setIsAuthenticated(state, value){
      state.isAuthenticated = value
    },
    setToken(state, value){
      state.token = value
      localStorage.setItem('token',state.token)
    }
  }
}