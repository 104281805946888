<template>
<v-navigation-drawer
      v-model="drawer"
      fixed
      hide-overlay
      temporary

      app
    >
      <v-list
        nav
        dense
        
      >
        <v-list-item-group
          v-model="group"
        
        >
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/catraca">
            <v-list-item-icon>
              <v-icon>mdi-boom-gate-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Catraca</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/cams">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Câmeras</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/workouts">
            <v-list-item-icon>
              <v-icon>mdi-weight-lifter</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Treinos</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/login">
            <v-list-item-icon>
              <v-icon>mdi-exit-run</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'Sidebar',
    data: ()=>({ group: null}),
    computed: {
      drawer:{
        get(){
          return this.$store.state.showSidebar
        },
        set(v){
          this.$store.commit('setShowSidebar', v)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>