export default {
  namespaced: true,
  state: ()=>({
    showTimeout: false,
    showGif: false,
    urlGif: '',
    timeout: 30,
    defaultTimeout: 30
  }),
  mutations:{
    setShowGif(state, value){
      state.showGif = value
    },
    setUrlGif(state, value){
      state.urlGif = value
    },
    settimeout(state, value){
      state.timeout = value
    },
    setShowTimeout(state, value){
      state.showTimeout = value
    }
  }
}