<template>
  <v-app id="app" dark style="background-image: url('img/monster.png'); background-position: center; background-repeat: no-repeat">
    <v-main>
      <router-view/>
        
      </v-main>
      <Topbar v-if="!$store.state.isLogin && $store.state.login.isAuthenticated" />
      <Sidebar v-if="!$store.state.isLogin  && $store.state.login.isAuthenticated" />
      <LoadingVue />
      <Alerts v-if="$store.state.alerts.message" @close="$store.commit('alerts/setMessage', false)" @confirm="$store.commit('alerts/setConfirm', true)" :text="$store.state.alerts.message" :type="$store.state.alerts.type" />
  </v-app>
</template>
<script>
import *  as face from 'face-api.js'
face.nets.tinyFaceDetector.load('/weights').then(() => window.faceOptions = new face.TinyFaceDetectorOptions({ inputSize: 512, scoreThreshold:0.5 }))
import LoadingVue from './components/Loading/Loading.vue'
import Sidebar from './components/Sidebar/Sidebar.vue'
import Topbar from './components/Topbar/Topbar.vue'
import Alerts from './components/Snackbar/Snackbar.vue'

export default {
  components: { Topbar, Sidebar, LoadingVue, Alerts },

}
</script>

<style lang="scss">

</style>
