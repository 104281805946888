export default {
  namespaced: true,
  state: ()=>({
    message: '',
    type: '',
    confirm: false,
    method: null
  }),
  mutations:{
    setMessage(state, value){
      state.message = value
      value || (state.confirm = value)
    },
    setType(state, value){
      state.type = value
    },
    setConfirm(state, value){
      state.confirm = value
    }
  },
  actions:{
    error({commit}, message){
      commit('setType', 'error')
      commit('setMessage', message)
    },
    success({commit}, message){
      commit('setType', 'success')
      commit('setMessage', message)
    },
    async confirm({commit}, message){
      await commit('setType', 'confirm')
      await commit('setMessage', message)
    }
  },
  
}