<template>
  <v-navigation-drawer
    :permanent="permanent"
    :mini-variant.sync="mini"
    right
    app
  >
    <v-list>
      <v-list-item>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-{{ !mini ? "left" : "right" }}</v-icon>
        </v-btn>
        <v-list-item-title class="text-center"
          >Faces não cadastradas</v-list-item-title
        >
      </v-list-item>
      <v-list-item class="px-2" v-for="(item, index) of faces" :key="index">
        <v-list-item-avatar>
          <v-img
            :src="$store.state.baseUrl + '/recognitions/avatar/' + item.name + '.jpg'"
          ></v-img>
        </v-list-item-avatar>
        <Autocomplete :item="item" @alunoId="save(item)" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import Autocomplete from './Autocomplete.vue'
export default {
  name: "FacesNotDetected",
  components: {Autocomplete},
  data: () => ({
    alunoId: null,
    mini: true,
    permanent: true,
    faces: [],
    timeout: 0,
    items: [],
    
    itemToSave: "",
  }),
  methods: {
    
    save(item) {
      this.alunoId = item._id
      this.itemToSave = item
      if(this.alunoId && confirm('Atribuir esta foto ao usuário?')){
        this.attribuition()
      }
    },
    async attribuition() {
      try {
        const data = {
          file: this.itemToSave.name,
          fileId: this.itemToSave._id,
          alunoId: this.alunoId,
        }
        await this.$store.state.axios.post("/recognitions/atribuition", data);
        this.itemToSave = "";
        this.$store.dispatch('alerts/success', 'Atribuição concluída com sucesso')
        this.faces = this.faces.filter(item => item._id !== data.fileId)
        if(!this.faces.length) this.$emit('close')
      } catch (error) {
        this.$store.dispatch('alerts/error', error.response ? error.response.data.error : error.stack)
      }
      this.alunoId = null
    },
  },
  async mounted() {
    const faces = await this.$store.state.axios.get("/recognitions/faces-to-identifier")
      .then((data) => data.data)
      .catch((err) => console.log("err", err.stack));
      if(!faces.length) this.$emit('close')
    this.faces = faces
  },

};
</script>