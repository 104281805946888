export default {
  namespaced: true,
  state: ()=>({
    dialog: false,
  }),
  mutations:{
    setDialog(state, value){
      state.dialog = value
    }
  }
}