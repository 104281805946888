<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay fixed scrollable>
    <v-card align="center" >
      <v-toolbar color="green" class="flex-grow-0">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-text class="pt-5" >

        <video @click="crop" ref="video" :src-object.prop.camel="stream" width="300px" autoplay no-controls playsinline :controls="false"></video>
        <canvas ref="canvas" v-show="this.src" @click="save"></canvas>

      </v-card-text>
      
    </v-card>
    <div class="loading" style="position: fixed; width: 100vw;  height:100vh; top:0; left:0; background:rgba(0,0,0,0.5); overflow: hidden" v-if="loading">
      <v-row no-gutters style="height: 100%;" justify="center" align="center" class="text-h3">{{loading}}</v-row>
    </div>
  </v-dialog>
</template>

<script>
import *  as face from 'face-api.js'
export default {
  data: () => ({
    stream: null,
    src: null,
    loading: null,
  }),
  computed: {
    name() {
      const split = this.$store.state.student?.data?.name?.split(" ") || "";
      const firstName = split.slice(0, 1);
      const lastName = split.slice(-1);
      return firstName === lastName ? lastName : firstName + " " + lastName;
    },
    dialog: {
      get() {
        return this.$store.state.photoAluno.dialog;
      },
      set(v) {
        this.$store.commit("photoAluno/setDialog", v);
      },
    },
  },
  methods:{
    save(){
      this.loading = 'Salvando'
      const data = this.$store.state.axios.post('/student/avatar', {_id: this.$store.state.student.data._id, photo: this.src})
        .then(() => {
          this.$store.state.student.data.avatar = this.$store.state.student.data._id +'.jpg?v='+Date.now()
          this.dialog =false
          this.$store.dispatch('alerts/success', data.message)
        })
        .catch(err => {
          this.$store.dispatch('alerts/error', err.response?.data?.error || 'Falha interna no servidor!')
        })
    },
    cam(){
      navigator.mediaDevices.getUserMedia({video: { facingMode: 'environment' }, audio: false })
        .then(stream => {
          this.loading = 'Calibrando a camera...'
          this.stream = stream
          
            setTimeout(async () =>{
              this.faces = await face.detectAllFaces(this.$refs.video, window.faceOptions)
              this.loading = false
              }, 2000)
            
            
          })
        .catch(error => alert("falha ao iniciar a camera" + error.stack))
    },
    async crop(){

      this.loading = "Aguarde"
      
      try {
        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d', { willReadFrequently: true })
        
        // const w = this.$refs.video.videoWidth
        // const h = this.$refs.video.videoHeight
        // canvas.width = w * 0.1
        // canvas.height = h * 0.1
        //ctx.drawImage(this.$refs.video, 0,0, canvas.width, canvas.height)
        
        const faces = await face.detectAllFaces(this.$refs.video, window.faceOptions)
        if(!faces.length){
          this.loading = 'Não foi detectado uma face, tente novamente...'
          return setTimeout(()=>this.loading = false, 3000)
        }
        canvas.width = faces[0].box.width + 20
        canvas.height = faces[0].box.height + 50
        ctx.drawImage(this.$refs.video, faces[0].box.left - 10, faces[0].box.top - 40, canvas.width,canvas.height,0,0, canvas.width, canvas.height)
        this.src = canvas.toDataURL("image/jpeg");
      } catch (error) {
        this.loading = 'deu ruim'+error.stack
      }
      this.loading = false
      
    }
  },
  watch: {
    dialog(v) {
      if(v) return this.cam()
      this.src = null
      this.stream?.getTracks()?.forEach(track => track.stop())
    },
    
  },
};
</script>

<style lang="scss" scoped>
</style>