<template>
  <div class="text-center ma-2">

    <v-snackbar
      v-model="show"
      centered
    >
    <v-icon color="green" v-if="type=='success'">mdi-check-circle</v-icon>
     <v-icon color="red" v-if="type=='error'">mdi-close-octagon</v-icon>  {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="type ==='success'? 'green':'red'"
          text
          v-bind="attrs"
          @click="$emit('close')"
        >
          {{type==='confirm' ? 'cancelar' : 'OK'}}
        </v-btn>
        <v-btn
        v-if="type==='confirm'"
          :color="'green'"
          text
          v-bind="attrs"
          @click="confirm"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props:['text', 'type'],
  data: ()=>({
    show: true
  }),
  methods:{
    confirm(){
      this.$emit('confirm')
      this.$nextTick(() => this.$emit('close'))
    },
  },
  watch:{
    show(v){
      if(!v){
        setTimeout(()=>this.$emit('close'), 500)
      }
    }
  }
}
</script>