import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Students from '@/components/Students/Students.vue'
import store from '../store';
import axios from "axios";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: "Students",
        component: Students
      },
      {
        path: '/registration/:id',
        name: 'Registration',
        component: () => import('../views/Registration.vue')
      },
      {
        path: '/catraca',
        name: 'Catraca',
        component: () => import('../components/Catraca/Catraca.vue')
      },
      {
        path: '/cams',
        name: "Cams",
        component: () => import('../components/Cams/Cams.vue')
      },
      {
        path: '/workouts',
        name: "Workouts",
        component: () => import('../components/Workouts/Workouts.vue')
      }
    ]
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('../views/Login.vue')
  },

  

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    to.name === 'Login' ? store.commit('setIsLogin', true) : store.commit('setIsLogin', false)
    if (to.name !== 'Login' && !store.state.login.isAuthenticated) {
      const token = localStorage.getItem('token')
      if (!token) return router.push('/login') //window.location.href = '/login'
      await axios.post(store.state.baseUrl + '/login/verify', { token }).then(({ data }) => data)
      store.commit('login/setIsAuthenticated', true)
      store.commit('login/setToken', token)
      store.commit('setAxios', axios.create({ baseURL: store.state.baseUrl, headers: { token: store.state.login.token } }))
      next()
    }
    else next()

  } catch (error) {
    router.push('/login') // window.location.href = '/login'
  }
})

export default router
