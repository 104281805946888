<template>
  <div>
    <v-data-table :headers="headers" :items="desserts" :options.sync="options" :server-items-length="totalDesserts"
      :loading="loading" class="elevation-1 cursor-pointer"
      :footer-props="{ 'items-per-page-options': [10, 15, 20], firstIcon: 'mdi-arrow-collapse-left', lastIcon: 'mdi-arrow-collapse-right', 'items-per-page-text': 'Alunos por página' }">

      <template #top>
        <v-toolbar>

          <v-toolbar-title>
            <v-select v-model="monthBirth" @change="changeMonthBirth" class="pa-5" size="10" outlined dense hide-details
              label="Aniversariantes do mês"
              :items="['Todos', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"></v-select>
          </v-toolbar-title>
        </v-toolbar>
      </template>

      <template #item.name="{ item }">
        <span @click="goToStudent(item)">{{ item.name }}</span>
      </template>

      <template #item.paymentDate="{ item }">
        {{ getDate(item.paymentDate) }}
      </template>

      <template #item.startDate="{ item }">
        {{ getDate(item.startDate) }}
      </template>

      <template #item.birth="{ item }">
        {{ getDate(item.birth) }}
      </template>

      <template #item.paymentDueDate="{ item }">
        <span v-if="(new Date(item.paymentDueDate) - new Date()) >= 0 ? true : false" class="ok">OK</span>
        <span v-else class="pendente">Pendente</span>
      </template>

      <template #item.status="{ item }">
        <Status :item="item" />
      </template>

    </v-data-table>
  </div>
</template>

<script>
const date = new Date()
const month = date.getMonth() + 1
const monthFormated = month < 10 ? "0" + month : month.toString()
import Status from './Status.vue'
export default {
  components: { Status },
  data() {
    return {
      monthBirth: 'Todos',
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: { sortBy: ['paymentDate'], sortDesc: [false], filter: { $expr: { $eq: [{ $month: "$birth" }, monthFormated] } } },
      headers: [
        { text: 'Aluno', align: 'start', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Nascimento', value: 'birth' },
        { text: 'Último pagamento', value: 'paymentDate' },
        { text: 'Pagamento', value: 'paymentDueDate' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    async saveStatus() {
      this.$store.commit('setLoading', true)
      setTimeout(() => this.$store.commit('setLoading', false), 1000)
    },
    async changeMonthBirth() {
      if (this.monthBirth === 'Todos') {
        return this.options.filter = {}
      }
      this.options.filter = { $expr: { $eq: [{ $month: "$birth" }, this.monthBirth] } }
    },
    async goToStudent(item) {
      this.loading = true
      await this.$store.commit("student/setData", item)
      await this.$router.push({ name: 'Registration', params: { id: item._id } })
      this.loading = false
    },
    getDate(date) {
      const dateSplit = date?.split('T')
      return (dateSplit || [])[0]?.split('-').reverse().join('/')
    },
    getDataFromApi() {

      this.loading = true
      this.fakeApiCall().then(data => {
        this.desserts = data.items
        this.totalDesserts = data.total
        this.loading = false
      })
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    async fakeApiCall() {
      const students = await this.$store.state.axios.get('/students', { params: this.options })
        .then(data => data.data)
        .catch(err => {
          console.error(err.stack)
          this.$store.dipatch('alerts/error', 'Falha ao puscar dados de alunos no servidor, tente novamente pfv...')
          return []
        })

      return students

    },

  },
  mounted() {
    this.monthBirth = monthFormated
  }
}
</script>

<style scoped>
.cursor-pointer>>>tbody>tr:hover {
  cursor: pointer;
}

.ok,
.pendente {
  font-weight: bold;
}

.ok {
  color: greenyellow
}

.pendente {
  color: rgb(255, 73, 73)
}
</style>