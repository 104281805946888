<template>
    <v-dialog
      v-model="dialog"
      width="300px"
      persistent
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    computed:{
      dialog:{
        get(){
          return this.$store.state.loading
        },
        set(v){
          this.$store.commit('setLoading', v)
        }
      }
    }
  }
</script>