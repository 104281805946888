<template>
  <v-container fluid >
    <FacesNotDetected @close="showFaceNotDetected = false" v-if="false" />
    <PhotoAluno />
    <router-view />
    
  </v-container>
</template>

<script>
import PhotoAluno from '@/components/Modals/PhotoAluno.vue'
import peerJs from "peerjs";
import FacesNotDetected from "@/components/FacesNotDetected/FacesNotDetected.vue"
  export default {
    components: {FacesNotDetected, PhotoAluno},
    data:()=>({
      showFaceNotDetected: true,
      showRouterView: false,
      timeout: 0
    }),
    methods:{
      async ping(){
        clearTimeout(this.timeout)
        const devices = await this.$store.state.axios.get('/devices').then(data => data.data.filter(item => !item.includes('admin')))
        const devicesToRemove = this.$store.state.streams.filter(item => !devices.includes(item.clientId))
        !devicesToRemove.length || this.$store.state.streams.splice(this.$store.state.streams.indexOf(devicesToRemove), 1)
        this.timeout = setTimeout(()=>this.ping(), 60000)
      }
    },
    mounted(){
      if(this.$store.state.peer) return
      this.$store.commit('setPeer', new peerJs("admin_" + String(Date.now()), {
      host: process.env.VUE_APP_SERVER_URL,
      port: process.env.VUE_APP_SERVER_PORT,
      secure: true,
      path: "/peer",
      key: "peerjs",
      debug: 2,
    }))
    this.$store.state.peer.on('open', ()=>this.showRouterView = true)
    this.$store.state.peer.on('call', call => {
        call.answer()
        call.on('stream', remoteStream => {
          const exists = this.$store.state.streams.find(item => item.clientId === call.peer)
          if(exists) {
            this.$store.state.streams.splice(this.$store.state.streams.indexOf(exists), 1)
          }
          this.$store.commit('addStreming', {stream: remoteStream, clientId: call.peer})
          this.ping()
        })
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>