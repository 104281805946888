export default {
  namespaced: true,
  state: ()=>({
    data: {}
  }),
  mutations:{
    setData(state, value){
      state.data = value
    }
  }
}