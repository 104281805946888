import Vue from 'vue'
import Vuex from 'vuex'
import treinos from './treinos'
import modals from './modals'
import login from './login'
import student from './student'
import alerts from './alerts'
import photoAluno from './photoAluno'
Vue.use(Vuex)
const timeout = (time = 1000) => new Promise(resolve => setTimeout(resolve, time))
export default new Vuex.Store({
  state: {
    baseUrl: "https://"+process.env.VUE_APP_SERVER_URL+":"+process.env.VUE_APP_SERVER_PORT,
    axios: null,
    showSidebar: false,
    loading: false,
    isLogin: false,
    peer: null,
    streams: [],
    wss: 'wss://'+process.env.VUE_APP_SERVER_URL+":"+process.env.VUE_APP_SERVER_PORT_WSS + '/catraca'
  },
  mutations: {
    addStreming(state, value){
      state.streams.push(value)
    },
    setPeer(state, value){
      state.peer = value
    },
    setShowSidebar(state, value){
      state.showSidebar = value
    },
    setLoading(state, value){
      state.loading = value
    },
    setIsLogin(state, value){
      state.isLogin = value
    },
    setAxios(state, value){
      state.axios = value
    }
  },
  actions: {
    async socketPing({dispatch}){
      await timeout(15000)
      if(window.socket.readyState === 1){
        window.socket.send('pong')
      }else{
        dispatch('socketConnection', false)
      }
      await dispatch('socketPing')
    },
    socketConnection({state, dispatch}, ping){
      console.log(window.socket?.readyState)
      window.socket && window.socket.readyState === 1 || (window.socket = new WebSocket(state.wss, "$2a$10$grLCebp4Vz4EgQWb"))
      window.socket.onclose = () => setTimeout(() => dispatch('socketConnection', false), 2000)
      if(ping === undefined) dispatch('socketPing')
    }
  },
  modules: {
    treinos,
    modals,
    login,
    student,
    alerts,
    photoAluno
  }
})
