<template>

        <v-autocomplete
          v-model="alunoId"
          outlined
          dense
          label="Nome do Aluno"
          hide-details
          hide-no-data
          :items="items"
          :search-input.sync="search"
          item-text="name"
          item-value="_id"
          @change="$emit('save', item)"
        />
</template>

<script>
  export default {
    props: ['item'],
    data: () => ({
      alunoId: '',
      search: "",
      items: []
    }),
    methods: {
      getStudentByName() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (!this.search) return;
        this.loading = true;
        this.items = await this.$store.state.axios
          .get("/students/" + this.search)
          .then(({ data }) => data);
        this.loading = false;
      }, 200);
    },
    },
      watch: {
    search(v) {
      !v || this.getStudentByName();
    },
    alunoId(v){
      !v || this.$emit('alunoId', v)
    }
  },
  }
</script>

<style lang="scss" scoped>

</style>